import React, { useContext } from "react";
import "./ArticleDetail.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { PlaceHolder } from "../../assets";
import { fetchArticle } from "../../redux/layoutSlice";
import Breadcrumbs from "../../components/BreadCrumbs";
import ImageWithObjectFit from "../../components/ImageWrapperComponent";
import { swiperCarousel } from "../../utils/carouselFunction";
import { Swiper, SwiperSlide } from "swiper/react";
import PrintSvg from "../../assets/PrintSvg";
import PDFSvg from "../../assets/PDFSvg";
import WhatsAppSvg from "../../assets/WhatsAppSvg";
import { WindowContext } from "../../components/WindowResize/resize";
import moment from "moment";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import {
    calculateReadTime,
    parseWithRemoveBrTagsFromBody,
    shareOnWhatsapp,
} from "../../utils/articleHelpers";
import ReactPlayer from "react-player";
import useTranslation from "../../utils/useTranslation";
import { Dropdown } from "primereact/dropdown";
import PhotoGallery from "../../components/PhotoGallery";

function Articles({ contents, colors: colorObj }) {
    const { articleId: articleIdFromParams } = useParams();

    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();

    // const navigate = useNavigate();

    const { isMobile } = useContext(WindowContext);

    const [isLoading, setIsLoading] = useState(!!id);

    const [didDispatch, setDidDispatch] = useState(false);

    const [clickOnShareBtn, setClickOnShareBtn] = useState(false);

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const content = useSelector((state) => state.layout.content);

    const { t } = useTranslation();

    const { countryName } = useSelector((state) => state.auth);

    const {
        title,
        date,
        summary,
        body,
        microsite,
        detailImage,
        link,
        model_type,
        carousel,
        region,
        article_pdf,
        translate_languages,
        image_series,
        themes,
    } = content[id] || {};

    useEffect(() => {
        if (isLoading && !didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
        if (isLoading && content[id]) {
            setIsLoading(false);
        }
    }, [isLoading, content, didDispatch]);

    useEffect(() => {
        if (translate_languages) {
            const index = translate_languages.findIndex((e) => e.path === id);
            if (index !== -1) {
                setSelectedLanguage(translate_languages[index]);
            }
        }
    }, [id]);

    useEffect(() => {
        if (selectedLanguage) {
            // const newPath = `/articles${selectedLanguage.path}`;
            // navigate(newPath);
            // dispatch(fetchArticle({ id: selectedLanguage.path, lang: selectedLanguage.lang_code }));
        }
    }, [selectedLanguage]);

    const microsites = microsite && microsite.split(",")[0] === "Global" ? "Global" : countryName;

    return (
        <>
            <div className="articleMainSecation">
                <div className="container">
                    <div className="row mb-5 d-flex justify-content-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <Breadcrumbs model_type={model_type} themes={themes} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            {(model_type !== null || model_type !== "") && (
                                <div
                                    className="tagMain mt-3"
                                    style={{ color: colorObj?.primary_color }}
                                >
                                    {model_type}
                                </div>
                            )}
                            <h3 className="mb-5 mt-2" style={{ color: colorObj?.accent_color1 }}>
                                {title}
                            </h3>
                            <div className="iconHldr">
                                <div className="dateMain">
                                    <div
                                        className="dateTitleMain"
                                        style={{
                                            borderLeft: `2px solid ${colorObj?.accent_color1}`,
                                        }}
                                    >
                                        <h4 style={{ color: colorObj?.accent_color1 }}>
                                            {microsites}{" "}
                                        </h4>
                                        <div className="dateTime">
                                            <span style={{ color: colorObj?.accent_color3 }}>
                                                {region ? `${region} • ` : ""}
                                                {calculateReadTime(content[id])
                                                    ? `${calculateReadTime(content[id])} ${t(
                                                          "constantString.minRead",
                                                      )} • `
                                                    : ""}
                                                {moment(date).format("DD MMMM YYYY")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="icon-btn"
                                    style={{ color: colorObj?.accent_color3 }}
                                    onClick={() => setClickOnShareBtn(!clickOnShareBtn)}
                                >
                                    <i className="bi bi-share me-2" aria-hidden="true"></i>
                                    {t("mediaArticleString.share")}

                                    {clickOnShareBtn && (
                                        <div className="ShareSocial mt-2">
                                            <span>
                                                <i
                                                    className="bi bi-whatsapp"
                                                    onClick={() => shareOnWhatsapp()}
                                                ></i>
                                            </span>
                                            <span>
                                                <FacebookShareButton url={window.location.href}>
                                                    <i className="bi bi-facebook"></i>
                                                </FacebookShareButton>
                                            </span>
                                            <span>
                                                <TwitterShareButton url={window.location.href}>
                                                    <i className="bi bi-twitter-x"></i>
                                                </TwitterShareButton>
                                            </span>
                                            <span>
                                                <LinkedinShareButton url={window.location.href}>
                                                    <i className="bi bi-linkedin"></i>
                                                </LinkedinShareButton>
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <ul>
                                    <li className="cursor-pointer" onClick={() => window.print()}>
                                        <PrintSvg />
                                    </li>
                                    <li
                                        className="cursor-pointer"
                                        onClick={() => window.open(article_pdf, "_blank")}
                                    >
                                        <PDFSvg />
                                    </li>
                                </ul>
                                {translate_languages?.length > 0 && (
                                    <Dropdown
                                        options={translate_languages}
                                        optionLabel="language"
                                        value={selectedLanguage}
                                        onChange={(e) => setSelectedLanguage(e.value)}
                                        placeholder="language"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="articleImageMain">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 mobile-view">
                                {link === null || link === "" ? (
                                    <ImageWithObjectFit src={detailImage?.src} />
                                ) : (
                                    <ReactPlayer url={link} controls className="video-ratio" />
                                )}
                            </div>
                            <div className="col-12">
                                <div className="imgtext" style={{ color: colorObj?.accent_color1 }}>
                                    {detailImage?.title}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isMobile && (
                    <div className="col-12 mt-3 mb-3">
                        <div className="iconHldr-mobile">
                            <ul>
                                <li className="me-4">
                                    <WhatsAppSvg />
                                </li>
                                <li className="me-4">
                                    <PrintSvg />
                                </li>
                                <li className="me-4">
                                    <PDFSvg />
                                </li>
                            </ul>
                        </div>
                    </div>
                )}

                {summary !== "" && (
                    <div className="titleSecation">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div
                                        className="summary"
                                        style={{
                                            color: colorObj?.accent_color1,
                                            borderLeft: `2px solid ${colorObj?.primary_color}`,
                                        }}
                                    >
                                        {parse(summary || "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {body !== "" && (
                    <div className="articleTextSecation">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div>{parseWithRemoveBrTagsFromBody(body || "")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {image_series !== null && <PhotoGallery image_series={image_series} />}

                {carousel !== "" && carousel !== null && (
                    <div className="carouselSection mt-5">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="heading">You may also like</div>
                            </div>
                            <div className="col-12 p-sm-0 p-lg-2 p-md-3">
                                <Swiper {...swiperCarousel}>
                                    <SwiperSlide>
                                        <div className="card h-100">
                                            <div className="cardImg">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={PlaceHolder}
                                                />
                                            </div>
                                            <div className="card-body d-flex flex-column">
                                                <div className="tagText">HEALTH AND WELLNESS</div>
                                                <h3 className="underline">
                                                    What is the purpose of fasting in Ramadan?
                                                </h3>
                                                <div className="authorDateMain mt-auto">
                                                    <span className="author me-2">
                                                        Articles and Stories
                                                    </span>
                                                    <span className="date">| 32 minutes ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="card h-100">
                                            <div className="cardImg">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={PlaceHolder}
                                                />
                                            </div>
                                            <div className="card-body d-flex flex-column">
                                                <div className="tagText">HEALTH AND WELLNESS</div>
                                                <h3 className="underline">
                                                    What is the purpose of fasting in Ramadan?
                                                </h3>
                                                <div className="authorDateMain mt-auto">
                                                    <span className="author me-2">
                                                        Articles and Stories
                                                    </span>
                                                    <span className="date">| 32 minutes ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="card h-100">
                                            <div className="cardImg">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={PlaceHolder}
                                                />
                                            </div>
                                            <div className="card-body d-flex flex-column">
                                                <div className="tagText">HEALTH AND WELLNESS</div>
                                                <h3 className="underline">
                                                    What is the purpose of fasting in Ramadan?
                                                </h3>
                                                <div className="authorDateMain mt-auto">
                                                    <span className="author me-2">
                                                        Articles and Stories
                                                    </span>
                                                    <span className="date">| 32 minutes ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="card h-100">
                                            <div className="cardImg">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={PlaceHolder}
                                                />
                                            </div>
                                            <div className="card-body d-flex flex-column">
                                                <div className="tagText">HEALTH AND WELLNESS</div>
                                                <h3 className="underline">
                                                    What is the purpose of fasting in Ramadan?
                                                </h3>
                                                <div className="authorDateMain mt-auto">
                                                    <span className="author me-2">
                                                        Articles and Stories
                                                    </span>
                                                    <span className="date">| 32 minutes ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="card h-100">
                                            <div className="cardImg">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={PlaceHolder}
                                                />
                                            </div>
                                            <div className="card-body d-flex flex-column">
                                                <div className="tagText">HEALTH AND WELLNESS</div>
                                                <h3 className="underline">
                                                    What is the purpose of fasting in Ramadan?
                                                </h3>
                                                <div className="authorDateMain mt-auto">
                                                    <span className="author me-2">
                                                        Articles and Stories
                                                    </span>
                                                    <span className="date">| 32 minutes ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Articles;
