export const PAGES_API_LINK = 'https://dev.the.ismaili/Pages_Scripts/webservices/getContent_ismaili.php?type=page'

export const ARTICLES_API_LINK = 'https://dev.the.ismaili/Pages_Scripts/webservices/getContent_ismaili.php?type=article&path='

export const DIRECTORY_API_LINK = 'https://www.iiuk.org/Pages_Scripts/webservices/globalapp_V3.php'

export const COUNTRY_API_LINK = 'https://dev.the.ismaili/Pages_Scripts/webservices/country_selector_data.php'

export const MESSAGES_API_LINK = 'https://www.iiuk.org/Pages_Scripts/webservices/messages_web.php'

export const MAILING_LIST_API_LINK = 'https://www.iiuk.org/Pages_Scripts/webservices/mail_list_web.php'

export const FUNERALS_SAMARS_API_LINK = 'https://www.iiuk.ncsect.org/Pages_Scripts/webservices/funerals_samars_web.php'

export const SPEECH_GALLERY_DETAILS_API_LINK = 'https://dev.the.ismaili/Pages_Scripts/webservices/getContent_ismaili.php?type=speeches'

export const SEARCH_RESULT_API_LINK = 'https://dev.the.ismaili/Pages_Scripts/webservices/getContent_ismaili.php?type=search&search='

export const JOB_SPOT_API_LINK = 'https://dev.ismaili.ncsect.org/Pages_Scripts/webservices/get_joblist.php'

export const INSTANT_SEARCH_POPULARITY_API_LINK = 'https://search.ncsect.org/analytics/events'

export const STATIC_TRANSLATE_DATA_API_LINK = 'https://dev.the.ismaili/Pages_Scripts/webservices/get_translated_static_data.php'

export const INITIAL_PAGE_NAME = 'home-page'