import { useSelector } from "react-redux";
import { selectAllCountriesAndLanguages } from "../redux/authSlice";

export const useCountryLanguageExtrator = (link) => {
    const { countryCode } = useSelector((state) => state.auth);
    const { languageName } = useSelector((state) => state.auth);

    const { countries, languages } = useSelector(selectAllCountriesAndLanguages);

    const linkParts = (link.startsWith("/") ? link.slice(1) : link).split("/");

    if (
        linkParts[0].length === 2 &&
        linkParts[1].length === 2 &&
        countries.includes(linkParts[0]) &&
        languages.includes(linkParts[1])
    )
        return [linkParts[0], linkParts[1], linkParts.slice(2).join("/")];
    return [countryCode, languageName, link];
};
