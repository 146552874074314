import parse from "html-react-parser";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Configure, Hits, InstantSearch } from "react-instantsearch";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import HamburgerMenuIconSvg from "../../assets/HamburgerMenuIconSvg";
import { APP_COLORS } from "../../constants/styles";
import { openSearchModal, setOpenSearchModal } from "../../redux/authSlice";
import { FACET_PARAMS, QUERY_PARAMS, serverConfig } from "../../utils/instantSearchConfig";
import { useGetLink, useOpenLink } from "../../utils/useOpenLink";
import useTranslation from "../../utils/useTranslation";
import { CountryLanguagePicker } from "../CountryLanguagePicker";
import CustomSearchBoxModal from "../InstantSearch/InstantCustomSearchBoxModal";
import InstantSearchModalArticleDetails from "../InstantSearch/InstantSearchModalArticleDetails";
import MegaMenu from "../MegaMenu/MegaMenu";
import MegaMenuMobile from "../MegaMenu/MegaMenuMobile";
import "./Header2.css";

function HeaderComponent2({ standardMenuHeader2: standard = {}, subHeader: sub = {} }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { getLink } = useGetLink();

    const isSearchModalOpen = useSelector(openSearchModal);

    const [selectedArticle, setSelectedArticle] = useState(null);

    const [searchKeyword1, setSearchKeyword1] = useState("");

    const standardMenuHeader = standard.contents || [];

    const [searchKeyword, setSearchKeyword] = useState("");

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const openLink = useOpenLink();

    const { t } = useTranslation();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const searchParams = queryParams.get("q");

        if (searchParams && searchParams !== "") {
            setSearchKeyword(searchParams);
        }
    }, []);

    useEffect(() => {
        if (searchKeyword && searchKeyword.length >= 3) {
            navigate(`/search?q=${searchKeyword}`);
        }

        if (searchKeyword && searchKeyword.length < 3) {
            navigate("/");
        }
    }, [searchKeyword]);

    useEffect(() => {
        setSelectedArticle(null);
    }, [searchKeyword1]);

    useEffect(() => {
        const header = document.querySelector("header.header-main");

        if (header && header.classList.contains("header2")) {
            document.body.classList.add("header-2");
        } else {
            document.body.classList.remove("header-2");
        }
    }, []); // Run once on mount

    const [typesenseAdapter, setTypesenseAdapter] = useState(
        new TypesenseInstantSearchAdapter({
            server: serverConfig,
            additionalSearchParameters: {
                query_by: QUERY_PARAMS.join(","),
                facet_by: FACET_PARAMS.join(","),
            },
        }),
    );

    useEffect(() => {
        const newAdapter = new TypesenseInstantSearchAdapter({
            server: serverConfig,
            additionalSearchParameters: {
                query_by: QUERY_PARAMS.join(","),
                facet_by: FACET_PARAMS.join(","),
            },
        });
        setTypesenseAdapter(newAdapter);
    }, []);

    const redirectToInstantSearchPage = () => {
        document.body.classList.remove("p-overflow-hidden");
        dispatch(setOpenSearchModal(false));
        navigate(`/search?q=${searchKeyword1}`);
    };

    const handleNavigation = () => {
        document.body.classList.remove("p-overflow-hidden");
        dispatch(setOpenSearchModal(false));
        window.scrollTo(0, 0);
    };

    const renderTopLinks = () => {
        return (
            <div className="w-full flex flex-row justify-between gap-x-6 container text-xs items-center">
                <div className="block md:invisible flex-grow">
                    <img
                        className=" cursor-pointer h-10"
                        src={standard?.section_image?.src}
                        onClick={() => openLink("/")}
                    />
                </div>

                {/* Show these links separately in desktop only. For mobile they appear with other nav links */}
                <div className="hidden md:flex md:flex-row md:gap-x-6">
                    {standardMenuHeader.map((value) =>
                        value.contents.map((v) => (
                            <div
                                key={value.heading}
                                className="cursor-pointer"
                                onClick={() => openLink(v.content_reference, v.type)}
                            >
                                {v.heading}
                            </div>
                        )),
                    )}
                </div>

                <CountryLanguagePicker />

                <button
                    className="navbar-toggler block md:hidden"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#topMenu"
                    aria-controls="topMenu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setMobileMenuOpen(true)}
                >
                    <HamburgerMenuIconSvg color={standard.colors?.primary_color} />
                </button>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className="w-full z-20 relative bg-white pb-6 shadow-md">
                {renderTopLinks()}

                {/* desktop */}
                <nav className="container hidden md:block">
                    <div className="flex flex-row justify-between container text-accent_color3 items-end">
                        <Link to={"/"}>
                            <img
                                className="cursor-pointer h-16"
                                src={standard?.section_image?.src}
                            />
                        </Link>
                        <ul className="flex flex-row gap-x-8">
                            <li className="cursor-pointer font-bold">
                                <i className="bi bi-play p-2"></i>THE ISMAILI TV
                            </li>
                            <li
                                className="cursor-pointer"
                                onClick={() => dispatch(setOpenSearchModal(true))}
                            >
                                <i className="bi bi-search"></i>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    };

    return (
        <div style={APP_COLORS(standard.colors)} className="sticky top-0 left-0 right-0 z-10">
            {renderHeader()}

            {mobileMenuOpen && (
                <MegaMenuMobile
                    logo={standard?.section_image?.src}
                    style={APP_COLORS(sub.colors)}
                    standardMenuHeader={standardMenuHeader}
                    subMenuHeader={sub.contents}
                    setMobileMenuOpen={setMobileMenuOpen}
                />
            )}
            <MegaMenu style={APP_COLORS(sub.colors)} subMenuHeader={sub.contents} />

            <Dialog
                visible={isSearchModalOpen}
                draggable={false}
                className="search-dialog"
                maximized={true}
                showHeader={false}
            >
                <div
                    className="modal-header cursor-pointer mt-3 header2-close-icon"
                    onClick={() => {
                        if (!isSearchModalOpen) return;
                        dispatch(setOpenSearchModal(false));
                    }}
                    style={{ "--primary_color": standard.colors?.primary_color }}
                >
                    <div
                        className="closeIconMain ms-auto"
                        onClick={() => dispatch(setOpenSearchModal(false))}
                    ></div>
                </div>
                <div
                    className="container mt-5"
                    style={{ "--primary_color": standard.colors?.primary_color }}
                >
                    <InstantSearch
                        indexName="app_ismaili_articles"
                        searchClient={typesenseAdapter.searchClient}
                    >
                        <Configure hitsPerPage={10} />

                        <CustomSearchBoxModal
                            initialQuery={searchKeyword1}
                            onQueryChange={setSearchKeyword1}
                            closeModal={() => dispatch(setOpenSearchModal(false))}
                            colorObj={standard.colors}
                            isSearchModalOpen={isSearchModalOpen}
                        />
                        {searchKeyword1 && (
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                    <Hits
                                        hitComponent={(hitProps) => (
                                            <InstantSearchModalArticleDetails
                                                {...hitProps}
                                                setSelectedArticle={setSelectedArticle}
                                                colorObj={standard.colors}
                                            />
                                        )}
                                    />
                                    <div
                                        className="ms-3 cursor-pointer showAll"
                                        onClick={() => redirectToInstantSearchPage()}
                                    >
                                        {t("btnText.ShowAll")}
                                        <i className="bi bi-arrow-right ms-2"></i>
                                    </div>
                                </div>
                                {selectedArticle && (
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="newsCard card">
                                            <div className="cardImg">
                                                <img
                                                    className="cursor-pointer"
                                                    src={selectedArticle.image}
                                                    alt="placeholder"
                                                />
                                            </div>
                                            <div className="card-body d-flex flex-column">
                                                <Link to={getLink(selectedArticle.path)}>
                                                    <h3
                                                        className="hover:underline cursor-pointer"
                                                        onClick={() => handleNavigation()}
                                                    >
                                                        {selectedArticle.title}
                                                    </h3>
                                                </Link>
                                                <div className="author me-1">
                                                    {selectedArticle.tags}
                                                </div>
                                                <div className="summary">
                                                    {parse(selectedArticle.summary)}
                                                </div>
                                                <Link to={getLink(selectedArticle.path)}>
                                                    <i
                                                        className="bi bi-arrow-right arrow-icon"
                                                        aria-hidden="true"
                                                        onClick={() => handleNavigation()}
                                                    ></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </InstantSearch>
                </div>
            </Dialog>
        </div>
    );
}

export default HeaderComponent2;
