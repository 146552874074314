import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { decodeString } from "../utils/objEncoder";
import { oauthServers } from "./mockData";
import { getAuthToken, getBaseUrl, removeAuthToken, storeAuthToken } from "../utils/oauthHandler";
import { getCodeVerifier, removeCodeVerifier } from "../utils/oauthHelpers";

const SLICE_NAME = "auth";

// call to api to return country list
export const getAllCountries = createAsyncThunk(`${SLICE_NAME}/getCountries`, async () => {
    try {
        const res = await fetch(
            "https://app.ismaili/Pages_Scripts/webservices/globalapp_v3.php?type=registration_data",
        );
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
    }
});

export const getCountriesData = createAsyncThunk(`${SLICE_NAME}/getCountriesData`, async () => {
    try {
        const res = await fetch(
            "https://dev.ismaili.ncsect.org/Pages_Scripts/webservices/country_selector_data.php",
        );
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
    }
});

export const checkToken = createAsyncThunk(`${SLICE_NAME}/checkToken`, async (oauthDetails) => {
    const token = getAuthToken();
    const axiosInstance1 = axios.create({
        headers: {
            "content-type": "text/plain",
        },
    });

    axiosInstance1.interceptors.request.use(
        (config) => {
            const getToken = getAuthToken();

            if (getToken && getToken !== "null") {
                config.headers["authorization"] = `Bearer ${getToken}`;
            }
            return config;
        },

        (error) => {
            return Promise.reject(error);
        },
    );

    axiosInstance1.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error && error.response) {
                const errResp = error.response;

                if (errResp) {
                    setTimeout(() => {
                        if (error.response && error.response.status === 511) {
                            if (error.response.data === "") {
                                removeAuthToken();
                            }
                        }
                    }, 200);
                }
            }
            return Promise.reject(error);
        },
    );

    const responseMe = await axiosInstance1.post(oauthDetails?.dataUri, {
        token: token,
        type: "oauth",
    });

    if (responseMe.data?.token) storeAuthToken(responseMe.data.token);
    return responseMe.data;
});

export const checkCode = createAsyncThunk(
    `${SLICE_NAME}/checkCode`,
    async ({ code, state, oauthDetails }) => {
        try {
            const { link = null } = decodeString(state);
            const { tokenEndpoint, clientId } = oauthDetails;
            const codeVerifier = getCodeVerifier();

            const dataToSend = {
                code,
                grant_type: "authorization_code",
                client_id: clientId,
                redirect_uri: getBaseUrl().base,
                code_verifier: codeVerifier,
            };
            const formData = new FormData();
            Object.keys(dataToSend).forEach((k) => formData.append(k, dataToSend[k]));
            const responseToken = await axios.post(tokenEndpoint, formData);
            const { access_token, id_token } = responseToken.data;
            removeCodeVerifier();

            storeAuthToken(access_token);

            const responseMe = await axios.post(oauthDetails?.dataUri, {
                token: access_token,
                type: "oauth",
            });
            return { ...responseMe.data, idToken: id_token, link };
        } catch (error) {
            console.error(error);
        }
    },
);

export const getCoutryFromIPAddress = createAsyncThunk(`${SLICE_NAME}/getCountry`, async () => {
    try {
        const res = await fetch(
            "https://app.ismaili/Pages_Scripts/webservices/get_country_details.php",
        );
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
    }
});

export const checkCountryInfo = createAsyncThunk(
    `${SLICE_NAME}/checkCountryInfo`,
    async (_, { getState, dispatch }) => {
        try {
            const state = getState();
            if (state.auth.countryName) return true;

            const countryNameFromLocalStorage = localStorage.getItem("country");
            const countryCodeFromLocalStorage = localStorage.getItem("code");
            if (countryCodeFromLocalStorage) {
                dispatch(
                    setCountryInfo({
                        name: countryNameFromLocalStorage,
                        code: countryCodeFromLocalStorage,
                    }),
                );
                return true;
            }

            const res = await fetch(
                "https://app.ismaili/Pages_Scripts/webservices/get_country_details.php",
            );
            const data = await res.json();
            const countryNameFromIP = data.country_name;
            const countryCodeFromIP = data.country_code;

            if (countryCodeFromIP && countryNameFromIP) {
                dispatch(setCountryInfo({ name: countryNameFromIP, code: countryCodeFromIP }));
                return true;
            }

            dispatch(setCountryInfo({ name: null, code: "" }));

            return true;
        } catch (error) {
            console.error(error);
        }
    },
);

export const setCountryInfo = createAsyncThunk(
    `${SLICE_NAME}/setCountryInfo`,
    async ({ name, code }) => {
        try {
            if (code !== "") {
                localStorage.setItem("country", name);
                localStorage.setItem("code", code);
            }
            return { name, code };
        } catch (error) {
            console.error(error);
        }
    },
);

export const checkLanguageInfo = createAsyncThunk(
    `${SLICE_NAME}/checkLanguageInfo`,
    async (_, { getState, dispatch }) => {
        try {
            const state = getState();
            if (state.auth.languageName) return true;

            const languageNameFromLocalStorage = localStorage.getItem("selectedLanguage");
            const directionFromLocalStorage = localStorage.getItem("selectedDirection");
            if (languageNameFromLocalStorage) {
                dispatch(
                    setLanguageInfo({
                        languageName: languageNameFromLocalStorage,
                        direction: directionFromLocalStorage,
                    }),
                );
                return true;
            }

            const bydefaultLanguageName = "en";
            const bydefaultDirection = "ltr";

            if (bydefaultLanguageName) {
                dispatch(
                    setLanguageInfo({
                        languageName: bydefaultLanguageName,
                        direction: bydefaultDirection,
                    }),
                );
                return true;
            }

            dispatch(setLanguageInfo({ languageName: "", direction: "" }));

            return true;
        } catch (error) {
            console.error(error);
        }
    },
);

export const setLanguageInfo = createAsyncThunk(
    `${SLICE_NAME}/setLanguageInfo`,
    async ({ languageName, direction }) => {
        try {
            if (languageName !== "") {
                localStorage.setItem("selectedLanguage", languageName);
                localStorage.setItem("selectedDirection", direction);
            }
            return { languageName, direction };
        } catch (error) {
            console.error(error);
        }
    },
);

const initialState = {
    jurisdiction: [],
    userInfo: {},
    isLoading: false,
    hasError: false,
    countryNameFromIPAddress: "",
    countryCodeFromIPAddress: "",
    redirectLink: null,
    showModal: false,
    openDropdown: false,
    countryName: null,
    countryCode: null,
    openSearchModal: false,
    countriesLanguageData: [],
    languageName: null,
    direction: null,
    jurisdictionNameRedux: null,
    oauthData: {},
    messageAPIFromAPI: "",
};

export const slice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        clearAuthState: (state) => {
            removeAuthToken();
            window.localStorage.removeItem("oauthServersDetails");
            state.userInfo = {};
        },
        clearRedirectLink: (state) => {
            state.redirectLink = null;
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload;
        },
        setOpenDropdown: (state, action) => {
            state.openDropdown = action.payload;
        },
        setOpenSearchModal: (state, action) => {
            state.openSearchModal = action.payload;
        },
        setOauthData(state, action) {
            state.oauthData = action.payload;
        },
        setjurisdictionNameRedux(state, action) {
            state.jurisdictionNameRedux = action.payload;
        },
        setMessagesAPI(state, action) {
            state.messageAPIFromAPI = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCountries.fulfilled, (state, action) => {
                // adds countries to state from axios api call
                if (action.payload !== null) {
                    state.countries = action.payload.country;
                    state.jurisdiction = action.payload.jurisdiction;
                    state.isLoading = false;
                    state.hasError = false;
                }
            })
            .addCase(getCountriesData.fulfilled, (state, action) => {
                // adds countries to state from axios api call
                if (action.payload !== null) {
                    state.countriesLanguageData = action.payload;
                }
            })
            .addCase(getCoutryFromIPAddress.fulfilled, (state, action) => {
                // adds countries to state from axios api call
                if (action.payload !== null) {
                    state.countryNameFromIPAddress = action.payload.country_name;
                    state.countryCodeFromIPAddress = action.payload.country_code;
                }
            })
            .addCase(checkCode.fulfilled, (state, action) => {
                const { link, ...rest } = action.payload;
                state.redirectLink = link || "/"; // default redirect is to /
                state.userInfo = rest;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(checkToken.fulfilled, (state, action) => {
                state.userInfo = action.payload;
            })
            .addCase(setCountryInfo.fulfilled, (state, action) => {
                state.countryCode = action.payload.code;
                state.countryName = action.payload.name;
            })
            .addCase(setLanguageInfo.fulfilled, (state, action) => {
                state.languageName = action.payload.languageName;
                state.direction = action.payload.direction;
            })
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("fulfilled"),
                (state) => {
                    state.isLoading = false;
                    state.hasError = false;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("pending"),
                (state) => {
                    state.isLoading = true;
                    state.hasError = false;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("rejected"),
                (state) => {
                    state.isLoading = false;
                    state.hasError = true;
                },
            );
    },
});

// countries from state
export const selectCountriesLanguage = (state) =>
    state[SLICE_NAME].countriesLanguageData
        .map((c) => ({ ...c, ...oauthServers }))
        .filter((c) => c.clientId);

export const selectAllCountriesAndLanguages = (state) => {
    const validCountriesAndLanguages = state[SLICE_NAME].countriesLanguageData.reduce(
        (acc, cur) => {
            if (cur.country_code && cur.language?.length > 0) {
                const newLang = cur.language[cur.language.length - 1].code;
                return {
                    countries: [...acc.countries, cur.country_code.toLowerCase()],
                    langs: { ...acc.langs, [newLang]: 1 },
                };
            }
            return { ...acc };
        },
        { langs: {}, countries: [] },
    );
    return {
        countries: validCountriesAndLanguages.countries,
        languages: Object.keys(validCountriesAndLanguages.langs),
    };
};

export const jurisdictionList = (state) => state[SLICE_NAME].jurisdiction;

export const selectUserInfo = (state) => state[SLICE_NAME].userInfo;
export const selectIsAuthed = (state) => !!state[SLICE_NAME].userInfo?.token;
export const selectRedirectLink = (state) => state[SLICE_NAME].redirectLink;

export const selectLoadingState = (state) => state[SLICE_NAME].isLoading;
export const selectErrorState = (state) => state[SLICE_NAME].hasError;
export const countryNameFromIPAddress = (state) => state[SLICE_NAME].countryNameFromIPAddress;
export const selectShowModal = (state) => state[SLICE_NAME].showModal;
export const openLoginDropdown = (state) => state[SLICE_NAME].openDropdown;
export const openSearchModal = (state) => state[SLICE_NAME].openSearchModal;
export const selectCountryNameFromIP = (state) => state[SLICE_NAME].countryNameFromIPAddress;
export const selectCountryCodeFromIP = (state) => state[SLICE_NAME].countryCodeFromIPAddress;
export const oauthData = (state) => state[SLICE_NAME].oauthData;
export const jurisdictionNameRedux = (state) => state[SLICE_NAME].jurisdictionNameRedux;
export const messageAPIFromAPI = (state) => state[SLICE_NAME].messageAPIFromAPI;

export const {
    clearAuthState,
    clearRedirectLink,
    setShowModal,
    setOpenDropdown,
    setOpenSearchModal,
    setOauthData,
    setjurisdictionNameRedux,
    setMessagesAPI,
} = slice.actions;
export default slice.reducer;
